import { RichTextContent } from '@/components/design/RichTextContent'
import { ButtonLink } from '@/components/ui/ButtonLinks'
import Image from 'next/image'
import { PagesHeaderBlock } from 'types/generated/contentful-types'

const renderTextContent = (
  textContent,
  heading,
  teaserCopy,
  title,
  borders,
  icon,
  cloudinaryIcon
) => {
  if (textContent) {
    return (
      <>
        <div
          className={`${
            borders ? 'border-t border-b border-darkShell py-6' : ''
          }`}
        >
          {icon && (
            <div className="w-10 mx-auto">
              <Image
                src={cloudinaryIcon?.[0]?.secure_url ?? icon?.url ?? ''}
                alt={
                  cloudinaryIcon?.[0]?.metadata?.alt ||
                  icon?.description ||
                  `Icon`
                }
                width={40}
                height={40}
              />
            </div>
          )}
          {textContent && (
            <div className="m-auto page-blocks-page-content headerBlockRichTextSpecialCase lg:max-w-3xl">
              <RichTextContent richText={textContent.json} />
            </div>
          )}
        </div>
      </>
    )
  } else {
    return (
      <>
        {title && heading === 'h1' && (
          <h1 className="m-auto mb-6 lg:mb-8 fora-text-h1 lg:max-w-2xl">
            {title}
          </h1>
        )}
        {title && heading === 'h2' && (
          <h2 className="m-auto mb-6 lg:mb-8 fora-text-h2 lg:max-w-2xl">
            {title}
          </h2>
        )}
        {title && heading === 'h3' && (
          <h3 className="m-auto mb-6 lg:mb-8 fora-text-h3 lg:max-w-2xl">
            {title}
          </h3>
        )}
        {title && heading === 'h4' && (
          <h4 className="m-auto mb-6 lg:mb-8 fora-text-h4 lg:max-w-2xl">
            {title}
          </h4>
        )}
        {teaserCopy && (
          <div className="m-auto page-blocks-page-content lg:max-w-2xl">
            <RichTextContent richText={teaserCopy.json} />
          </div>
        )}
      </>
    )
  }
}

export type HeaderBlockProps = Omit<
  PagesHeaderBlock,
  'contentfulMetadata' | 'sys' | '_id'
> & {
  customTeaser?: string
  heading?: 'h1' | 'h2' | 'h3' | 'h4'
  border?: boolean
  paddingBottom?: boolean
  headerClass?: string
  titleStyle?: string | null
}

export default function HeaderBlock({
  title,
  textContent,
  teaserCopy,
  ctaText,
  ctaUrl,
  sectionId,
  customTeaser,
  heading = 'h2',
  border,
  paddingBottom = false,
  urlTarget,
  headerClass,
  ctaTheme,
  borders = false,
  footNote,
  icon,
  secondaryCtaText,
  secondaryCtaUrl,
  secondaryUrlTarget,
  cloudinaryIcon,
}: HeaderBlockProps): JSX.Element {
  const headerBlockClass = `${
    border ? 'mb-4 lg:pb-8 pt-12 lg:pt-22 border-t border-darkShell' : ''
  } ${headerClass ? headerClass : ''}`
  return (
    <section id={sectionId ?? ''} className={headerBlockClass}>
      <div
        className={`${
          paddingBottom ? 'pb-8 lg:pb-14 ' : ''
        } m-auto text-center`}
        data-name="header-block"
      >
        {renderTextContent(
          textContent,
          heading,
          teaserCopy,
          title,
          borders,
          icon,
          cloudinaryIcon
        )}
        {customTeaser && (
          <div className="m-auto page-blocks-page-content lg:max-w-xl">
            <div className="global-richtext">
              <p>{customTeaser}</p>
            </div>
          </div>
        )}
        {footNote && (
          <div className="m-auto mt-4 lg:max-w-xl">
            <p className="font-serif text-xs italic font-semibold md:text-sm text-darkStone">
              {footNote}
            </p>
          </div>
        )}
        <div className="flex justify-center gap-4">
          {ctaText && ctaUrl && (
            <div className="mt-8">
              <ButtonLink
                href={ctaUrl}
                target={(urlTarget || '_self') as '_self' | '_blank'}
                text={ctaText}
                theme={(ctaTheme || 'secondary') as 'primary' | 'secondary'}
              />
            </div>
          )}
          {secondaryCtaText && secondaryCtaUrl && (
            <div className="mt-8">
              <ButtonLink
                href={secondaryCtaUrl}
                target={(secondaryUrlTarget || '_self') as '_self' | '_blank'}
                text={secondaryCtaText}
                theme="transparent"
              />
            </div>
          )}
        </div>
        <div />
      </div>
    </section>
  )
}
